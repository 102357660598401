import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useAuthContext } from 'src/auth/hooks'
import { paths } from '../../routes/paths'
import { Constants } from '../../../../utils/constants'

// ----------------------------------------------------------------------

export default function NavHelpCenter() {
  const { logout } = useAuthContext()

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Button variant="contained" href={Constants.HELP_CENTER_LINK} target="_blank" rel="noopener" color="primary">
          Help Center
        </Button>
        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Button variant="text" onClick={logout} rel="noopener" color="primary">
            Log out
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
