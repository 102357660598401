import { m } from 'framer-motion'
// @mui
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import logger from 'src/utils/logger'
// routes
import { useRouter } from '../../routes/hooks'
import { paths } from '../../routes/paths'
// auth
import { useAuthContext } from '../../auth/hooks'
// components
import { varHover } from '../../components/animate'
import CustomPopover, { usePopover } from '../../components/custom-popover'
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: paths.home,
  },
  {
    label: 'Profile',
    linkTo: paths.settings.profile,
  },
  {
    label: 'Settings',
    linkTo: paths.settings.root,
  },
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter()

  const { user, logout } = useAuthContext()

  const popover = usePopover()

  const handleLogout = async () => {
    try {
      popover.onClose()
      await logout()
    } catch (error) {
      logger.error('AccountPopover', {}, error)
    }
  }

  const handleClickItem = (path: string) => {
    popover.onClose()
    router.push(path)
  }

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.avatarUrl}
          alt={user?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                handleClickItem(option.linkTo)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  )
}
